<template>
  <div class="eyeTraning">
    <vaeTitle title="本日护眼打卡训练"></vaeTitle>
    <div class="content">
      <div class="record flex">
        <div class="time flex1">
          <div class="title">时长</div>
          <div class="sel">{{ clockObj.time }}分钟</div>
        </div>
        <div class="biu"></div>
        <div class="time flex1">
          <div class="title">次数</div>
          <div class="sel">{{ clockObj.count }}次</div>
        </div>
      </div>
    </div>
    <vaeTitle title="视觉训练·近视防控"></vaeTitle>
    <div class="content">
      <div class="record flex2 mb20" @click="playVideo(item.video)" v-for="item in videoList" :key="item.id">
        <img :src="item.img" class="vover" alt="" />
        <div class="introduce">
          <div class="name">{{item.title}}</div>
          <div class="info">
            {{item.content}}
          </div>
        </div>

      </div>
    </div>
    <div class="input_video"></div>
    <van-overlay :show="showPlay" @click="hideShow">
      <div class="wrapper" @click.stop>
        <video
          id="my-player"
          class="video-js"
          ref="videoApp"
          controls
          preload="auto"
          poster=""
          data-setup="{}"
          :playsinline="true"
          :webkit-playsinline="true"
          x5-video-player-type="h5"
          :x5-video-player-fullscreen="true"
        >
          <source
            :src="videoUrl"
            type="video/mp4"
          />
        </video>
        <div class="bofang"><van-icon name="play-circle-o" size="20" color="#fff" v-show="is_show == false " @click="stopPlay" /><van-icon name="pause-circle-o" size="20" color="#fff" v-show="is_show == true " @click="stopPlay" /></div>
        <div class="quanping"><van-icon name="shrink" size="20" @click="fullScreen" color="#fff" /></div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import vaeTitle from '@/components/title.vue'
import { userClock, userClockSave } from '@/api/studentProduct'
import {trainList} from '@/api/eyeTraning'
import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  components: {
    vaeTitle
  },
  data () {
    return {
      showPlay: false,
      clockObj: {},
      currentTime: null,
      // 视频播放
      player: null,
      videoList:[],
      videoUrl:'',
      is_show:true
    }
  },
  created () {
    this.getList()
    this.traningList()
  },
  mounted(){
    let options = {
        autoplay: true, //自动播放
        language: "zh-CN",
        controls: true, //控制条
        preload: "auto", //自动加载
        errorDisplay: false, //错误展示
        fluid: true, //跟随外层容器变化大小，跟随的是外层宽度
        width: "100%",
        height: "400px",
        // controlBar: false,  // 设为false不渲染控制条DOM元素，只设置controls为false虽然不展示，但是存在
        // textTrackDisplay: false,  // 不渲染字幕相关DOM
        userActions: {
          hotkeys: true //是否支持热键
        },
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        // techOrder: ["h5","flash"],//定义Video.js技术首选的顺序
    };
    this.player = Videojs(
        this.$refs.videoApp,
        options,
        function onPlayerReady() {
          Videojs.log(`Your player is ready!`);
          this.play();
        }
    );
    
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    async getList () {
      const res = await userClock()
      if (res.data.code == 200) {
        this.clockObj = res.data.data
      }
    },
    async traningList() {
      const res = await trainList()
      if (res.data.code == 200) {
        this.videoList = res.data.data
      }
    },
    playVideo (url) {
      const _self = this
      // _self.videoUrl = url
      let el = _self.$refs.videoApp
      el.src = url
      // console.log(this.videoUrl,'playVideo');
        this.showPlay = true
        this.$refs.videoApp.play()
      this.currentTime != null
        ? (this.$refs.videoApp.currentTime = this.currentTime)
        : (this.$refs.videoApp.currentTime = 0)
    },
    async hideShow () {
      this.showPlay = false
      this.$refs.videoApp.src = ''
      this.$refs.videoApp.pause()
      this.currentTime = this.$refs.videoApp.currentTime
      const res = await userClockSave({ duration: this.currentTime })
      if (res.data.code == 200) {
        this.getList()
      }
    },
    stopPlay() {
      this.is_show = !this.is_show
      this.is_show == true ? this.$refs.videoApp.play() : this.$refs.videoApp.pause()
    },
    fullScreen () {
      console.log(this.$refs);
      // this.$refs.videoApp.exitFullscreen()
    }
  }
}
</script>

<style lang="less" scoped>
.eyeTraning {
  width: 100%;
  background: #f8f9f9;
  height: calc(100vh - 145px);
  padding-top: 15px;
  .content {
    padding: 15px;
    .record {
      background-color: #fff;
      padding: 16px 15px;
      border-radius: 10px;
      box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
      color: #333333;
      .biu {
        width: 1px;
        height: 73px;
        background-color: #ccc;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      .sel {
        margin-top: 15px;
        font-size: 13px;
      }
      .vover {
        border-radius: 10px;
        width: 82px;
        height: 82px;
      }
      .introduce {
        padding-left: 20px;
        color: #333333;
        .name {
          font-size: 16px;
          line-height: 22px;
        }
        .info {
          margin-top: 10px;
          color: #666666;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .wrapper {
    margin-top: 50% !important;
    position: relative;
    .bofang {
      position: absolute;
      top: 150px;
      left: 10px;
      z-index: 999999;
    }
    .quanping {
      position: absolute;
      top: 150px;
      right: 10px;
      z-index: 999999;
    }
  }
  .video-js {
    width: 100%;
    height: 200px;
  }
  ::v-deep .video-js .vjs-big-play-button {
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ::v-deep .video-js .vjs-time-control {
    display: none !important;
  }
  ::v-deep .video-js .vjs-volume-panel {
    display: none !important;
  }
  ::v-deep .vjs-slider {
    display: none !important;
  }
  ::v-deep .video-js .vjs-picture-in-picture-control {
    display: none;
  }
  .myControls {
    .videoBtn {
      width: 30px;
      height: 30px;
    }
  }
  
  .flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .flex1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .flex2 {
    display: flex;
  }
  .mb20 {
    margin-bottom: 15px;
  }
  ::v-deep .my-player-dimensions.vjs-fluid {
    padding-top: 50%;
  }
}
</style>
